import { Ability } from '@casl/ability'
import { store } from '@store/storeConfig/store'

const ability = new Ability([])

function defineRulesFor(user) {
    const abilities = []

    abilities.push(
        {
            action: ['manage'],
            subject: 'all'
        }
    )

    return abilities
}

let currentUser;
store.subscribe(() => {
    const prevUser = currentUser;
    currentUser = store.getState().auth.userData;
    if (currentUser && prevUser !== currentUser) {
        ability.update(defineRulesFor(currentUser));
    }
});

export default ability
